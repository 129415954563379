<template>
  <div
    class="flex justify-between w-full h-32 rounded-t-lg"
    :class="bgColor"
  >
    <div
      class="flex items-start font-semibold text-xl text-white p-4"
    >
      {{ title }}
    </div>
    <div class="flex-none items-center justify-end h-24 px-4 py-2">
      <div
        class="flex justify-center items-center h-20 w-20 bg-white rounded-full"
        v-if="headerImage"
      >
        <img
          class="h-12"
          :src="headerImage"
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    bgColor: {
      type: String,
      default: 'bg-indigo-500'
    },
  },
  setup (props) {
    const headerImage = computed(() => {
      if (props.icon) {
        try {
          const img = require(`@/assets/img/tools/${props.icon}`)
          return img
        } catch (error) {
          // const fallbackImage = require('@/assets/profile/manyrobot-profile.svg')
          // return fallbackImage
          return null
        }
      }
      return null
    })

    return {
      headerImage
    }
  }
})
</script>
