
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    bgColor: {
      type: String,
      default: 'bg-indigo-500'
    },
  },
  setup (props) {
    const headerImage = computed(() => {
      if (props.icon) {
        try {
          const img = require(`@/assets/img/tools/${props.icon}`)
          return img
        } catch (error) {
          // const fallbackImage = require('@/assets/profile/manyrobot-profile.svg')
          // return fallbackImage
          return null
        }
      }
      return null
    })

    return {
      headerImage
    }
  }
})
